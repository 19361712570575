export default {
    //Configurable
    Billboard: () => import('./configurable/Billboard'),
    Funnel: () => import('./configurable/Funnel'),
    Graph: () => import('./configurable/Graph'),
    Table: () => import('./configurable/Table'),
    Mixed: () => import('./configurable/Mixed'),
    Column: () => import('./configurable/Column'),
    Bar: () => import('./configurable/Bar'),
    Donut: () => import('./configurable/Donut'),
    Custom: () => import('./configurable/Custom'),

    //Preconfigured(metrics are not configurable)
    AdvertisingFunnel: () => import('./preconfigured/AdvertisingFunnel'),
    OfflineEventsMatchback: () => import('./preconfigured/OfflineEventsMatchback'),
};