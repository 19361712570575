<template>
    <div class="branded-container">
        <div class="top-bar">
            <div
                class="brand-color"
                :style="`background:${brand.color}`" />
        </div>
        <slot />
        <div class="bottom-bar">
            <div
                class="brand-color"
                :style="`background:${brand.color}`" />
            <div class="meta">
                <div class="items">
                    <div class="item account">
                        <p class="label">
                            Account:
                        </p>
                        <p class="value">
                            {{ dealer.name }}
                        </p>
                    </div>
                    <div class="item date-range">
                        <p class="label">
                            Date Range:
                        </p>
                        <p class="value">
                            {{ dateRangeFormatted }}
                        </p>
                    </div>
                </div>
                <div class="logo">
                    <img :src="brand.logo">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BrandedContainer',
    props: {
        brand: {
            type: Object,
            required: true
        },
        dealer: {
            type: Object,
            required: true
        },
        dateRangeFormatted: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.branded-container {
    position: relative;
    height: 100%;
    padding: 30px;
    background: #EDF0F0;
}
.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 100%;
    background: #33373A;

    .brand-color {
        width: 150px;
        height: 100%;
    }
}
.bottom-bar {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: #33373A;

    .brand-color {
        width: 150px;
        height: 100%;
    }
    .meta {
        display: flex;
        align-items: center;
        flex-direction: row;
        .items {
            display: flex;
            float: left;
            flex-direction: row;
            align-items: center;
        }
        .logo {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 10px 30px;
            height: 60px;
            img {
                height: 40px;
                width: auto;
            }
        }
    }
    .account,
    .date-range {
        padding: 0 15px;
    }
    .account {
        padding-right: 50px;
        border-right: 1px solid #ccc;
    }

    .label {
        color: #8F9EA6;
        font-weight: 600;
        margin: 0;
    }
    .value {
        color: $white;
        font-weight: 600;
    }
}
</style>