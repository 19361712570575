<template>
    <div
        class="page-preview"
        :class="page.meta.background">
        <branded-container
            v-if="page.meta.branded"
            :brand="brand"
            :dealer="dealer"
            :date-range-formatted="dateRangeFormatted">
            <div class="page-header">
                <p
                    v-if="page.meta.branded && page.title.length > 0"
                    class="title"
                    :class="{small: page.meta.small_title}">
                    {{ formatTitle(page.title) }}
                </p>
                <a
                    v-if="action"
                    class="cta"
                    :href="action.url">{{ action.text }}</a>
            </div>
            <loader
                v-if="loading"
                class="loading" />
            <div v-else-if="dealerMissingFeature">
                <p class="dealer-missing-feature">
                    This page will not be displayed when generating this report because the selected dealer does not have the required product features.
                </p>
            </div>
            <component
                :is="layoutComponent"
                v-else>
                <template
                    v-for="(slot,s) in slots"
                    #[slot.name]>
                    <p
                        v-if="slot.title.length > 0"
                        :key="'widget-title-' + s"
                        class="widget-title">
                        <img
                            v-if="slot.showIcon && channelIcon(slot)"
                            :src="`/img/platforms/${channelIcon(slot)}.svg`">
                        {{ slot.title }}
                    </p>
                    <component
                        :is="slot.component"
                        v-if="slot.type == 'widget'"
                        :key="s"
                        :meta="slot"
                        :size="slot.size"
                        :channel="slot.channel"
                        :metrics="getMetrics(slot, slot.channel)"
                        @add-page="addPage" />
                    <span
                        v-else-if="slot.type == 'image'"
                        :key="s">
                        <img :src="slot.value">
                    </span>

                    <span
                        v-else
                        :key="s">{{ slot.value }}</span>
                </template>
            </component>
        </branded-container>
        <div v-else>
            <div class="page-header">
                <p
                    v-if="page.meta.branded && page.title.length > 0"
                    class="title"
                    :class="{small: smallTitle}">
                    {{ page.title }}
                </p>
                <a
                    v-if="action"
                    class="cta"
                    :href="action.url">{{ action.text }}</a>
            </div>
            <loader
                v-if="loading"
                class="loading" />
            <div v-else-if="dealerMissingFeature">
                <p class="dealer-missing-feature">
                    This page will not be displayed because the selected dealer does not have the required feature.
                </p>
            </div>
            <component
                :is="layoutComponent"
                v-else
                :brand="brand">
                <template
                    v-for="(slot,s) in slots"
                    #[slot.name]>
                    <div
                        v-if="slot.type == 'widget'"
                        :key="s">
                        <component
                            :is="slot.component"
                            :meta="slot"
                            :size="slot.size"
                            :metrics="getMetrics(slot, slot.channel)"
                            @add-page="addPage" />
                    </div>
                    <span
                        v-else-if="slot.type == 'image'"
                        :key="s">
                        <img :src="slot.value">
                    </span>

                    <span
                        v-else
                        :key="s">{{ slot.value }}</span>
                </template>
            </component>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BrandedContainer from './BrandedContainer';
import FacebookAdvertisingMetrics from './mapping/facebook/AdvertisingMetrics';
import SnapchatAdvertisingMetrics from './mapping/snapchat/AdvertisingMetrics';
import TiktokAdvertisingMetrics from './mapping/tiktok/AdvertisingMetrics';
import PinterestAdvertisingMetrics from './mapping/pinterest/AdvertisingMetrics';
import WidgetsAvailable from './widgets/widgetsAvailable';
import Loader from '@/components/globals/Loader';
import { get } from 'lodash';
import moment from 'moment';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import { PLATFORM_FACEBOOK, PLATFORM_TIKTOK, PLATFORM_SNAPCHAT, PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    name: 'PagePreview',
    components: {
        BrandedContainer,
        Loader,
        ...WidgetsAvailable
    },
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            template: (state) => state.report.template,
            agency: state => state.agency.currentAgency,
            dealer: state => state.dealer.currentDealer,
            dateRange: state => state.metrics.dateRange,
            metrics: state => state.metrics
        }),
        ...mapGetters([
            'activePage',
            'dealerHasFeatures'
        ]),
        ...mapGetters('metrics/facebook', {
            FacebookLoading: 'loading'
        }),
        ...mapGetters('metrics/tiktok/ads', {
            TiktokLoading: 'metricsLoading'
        }),
        ...mapGetters('metrics/snapchat/ads', {
            SnapchatLoading: 'metricsLoading'
        }),
        ...mapGetters('metrics/pinterest/ads', {
            PinterestLoading: 'metricsLoading'
        }),
        ...mapGetters('metrics/facebook/ads', {
            FacebookAdvertisingTotals: 'metricsTotalsFormatted',
            FacebookAdvertisingIntervals: 'metricsIntervalsFormatted',
            FacebookAdvertisingCampaigns: 'metricsCampaignsFormatted',
            FacebookAdvertisingInventory: 'metricsInventoryFormatted'
        }),
        ...mapState('metrics/snapchat/ads', {
            SnapchatAdvertisingTotals: state => state.metricsTotals,
            SnapchatAdvertisingIntervals: state => state.metricsIntervals,
        }),
        ...mapGetters('metrics/snapchat/ads', {
            SnapchatAdvertisingCampaigns: 'metricsCampaigns',
        }),
        ...mapState('metrics/tiktok/ads', {
            TiktokAdvertisingTotals: state => state.metricsTotals,
            TiktokAdvertisingIntervals: state => state.metricsIntervals,
        }),
        ...mapState('metrics/pinterest/ads', {
            PinterestAdvertisingTotals: state => state.metricsTotals,
            PinterestAdvertisingIntervals: state => state.metricsIntervals,
        }),
        ...mapGetters('metrics/pinterest/ads', {
            PinterestAdvertisingCampaigns: 'metricsCampaigns',
        }),
        ...mapGetters('metrics/tiktok/ads', {
            TiktokAdvertisingCampaigns: 'metricsCampaigns',
        }),
        loading() {
            const platform = this.page.meta.platform;
            const channels = new Set();
            if (!platform) {
                this.page.slots.forEach(s => {
                    const channel = s.channel ?? '';
                    if (channel) {
                        channels.add(channel.toLowerCase());
                    }
                });
            }
            let fbIsLoading = false;
            let tiktokIsLoading = false;
            let snapchatIsLoading = false;
            let pinterestIsLoading = false;
            if (channels.has(PLATFORM_FACEBOOK)) { fbIsLoading = this.FacebookLoading }
            if (channels.has(PLATFORM_TIKTOK)) { tiktokIsLoading = this.TiktokLoading }
            if (channels.has(PLATFORM_SNAPCHAT)) { snapchatIsLoading = this.SnapchatLoading }
            if (channels.has(PLATFORM_PINTEREST)) { pinterestIsLoading = this.PinterestLoading }
            return fbIsLoading || tiktokIsLoading || snapchatIsLoading || pinterestIsLoading;
        },
        dealerMissingFeature() {
            const requiredFeature = this.page.meta.required_feature;
            if(requiredFeature) {
                return  !this.dealerHasFeatures([requiredFeature]);
            }
            return false;
        },
        slots() {
            return this.page.slots.map(slot => {
                if(slot.eval) {
                    return {
                        name: slot.name,
                        type: slot.type,
                        value: get(this, slot.eval)
                    };
                }
                // fallback for old reports
                const slotChannel = slot.channel ?? null;
                if ((slot.channel === '' || !slotChannel) && this.page.meta.platform) {
                    slot.channel = this.page.meta.platform;
                }
                return slot;
            });
        },
        dateRangeFormatted() {
            return moment(this.dateRange.startDate).format('MMM D') + ' - ' + moment(this.dateRange.endDate).format('MMM D, YYYY');
        },
        layoutComponent() {
            const layout = this.page.meta.layout;
            return () => import(`./layouts/${layout}`);
        },
        agencyHasBrand() {
            return this.agency.white_label_theme_config.asset_urls.navbar_logo !== null ||
                    this.agency.white_label_theme_config.theme.primary_color !== null;
        },
        brand() {
            if(this.agencyHasBrand) {
                const primaryDomain = this.agency.primary_domain ?? this.agency.url ?? ''; // fallback to url if no domain
                return {
                    template_name: this.template.name,
                    color: this.agency.white_label_theme_config.theme.primary_color,
                    name: this.agency.name,
                    logo: this.agency.white_label_theme_config.asset_urls.navbar_logo,
                    web_url: this.agency.url?.replace('https://','').replace('http://','').replace(/\/$/, '') ?? '',
                    app_url: primaryDomain.replace(/\/$/, ''),
                };
            }
            // Default BuyerBridge Branding
            return {
                template_name: this.template.name,
                color: '#00A4EB',
                name: 'BuyerBridge',
                logo: 'https://res.cloudinary.com/du/image/upload/v1555619282/branding/buyerbridge-logo.svg',
                web_url: 'buyerbridge.io',
                app_url: 'https://app.buyerbridge.io'
            };
        },
        action() {
            if (this.page.action) {
                return {
                    text: this.page.action.text,
                    url: this.page.action.url.replace('<<dealer_url>>', this.brand.app_url + '/dealers/' + this.dealer.id)
                };
            }
            return false;
        }
    },
    watch: {
        loading(pageLoading) {
            this.$emit('loading', pageLoading);
        }
    },
    created() {
        this.$emit('loading', this.loading);
    },
    methods: {
        channelIcon(slot) {
            return slot.channel?.toLowerCase() ?? '';
        },
        getMetrics(slot, channel) {
            let metrics = {};
            if(!slot.metrics) {
                console.error('Metrics not defined for slot:', slot);
                return [];
            }
            slot.metrics.forEach(metric => {
                let meta = FacebookAdvertisingMetrics.find(meta => meta.key == metric);
                if (channel === capitalizeWord(PLATFORM_TIKTOK)) { meta = TiktokAdvertisingMetrics.find(meta => meta.key == metric) }
                if (channel === capitalizeWord(PLATFORM_SNAPCHAT)) { meta = SnapchatAdvertisingMetrics.find(meta => meta.key == metric) }
                if (channel === capitalizeWord(PLATFORM_PINTEREST)) { meta = PinterestAdvertisingMetrics.find(meta => meta.key == metric) }
                const platform = channel;
                const product = 'Advertising';
                if(this[platform + product + slot.dataType] instanceof Array && slot.dataType == 'Campaigns') {
                    // Campaign Level Metrics
                    if (!(metrics instanceof Array)) {
                        // Swap the metrics type to an Array
                        metrics = [];
                    }
                    this[platform + product + slot.dataType].forEach((campaign,i) => {
                        if(metrics[i]) {
                            if(campaign.hasOwnProperty('stats')) {
                                metrics[i].metrics.push({
                                    ...meta,
                                    ...campaign.stats[metric]
                                });
                            } else {
                                metrics[i].metrics.push({
                                    ...meta,
                                    ...campaign[metric]
                                });
                            }
                        } else {
                            if(campaign.hasOwnProperty('stats')) {
                                metrics[i] = {
                                    campaign_id: {
                                        name: 'Campaign ID',
                                        key: 'campaign_id',
                                        ...campaign.stats.campaign_id,
                                    },
                                    campaign_name: {
                                        name: 'Campaign Name',
                                        key: 'campaign_name',
                                        ...campaign.stats.campaign_name
                                    },
                                    metrics: [{
                                    ...meta,
                                    ...campaign.stats[metric]
                                    }]
                                };
                            } else {
                                metrics[i] = {
                                    campaign_id: {
                                        name: 'Campaign ID',
                                        key: 'campaign_id',
                                        ...campaign.campaign_id,
                                    },
                                    campaign_name: {
                                        name: 'Campaign Name',
                                        key: 'campaign_name',
                                        ...campaign.campaign_name
                                    },
                                    metrics: [{
                                    ...meta,
                                    ...campaign[metric]
                                    }]
                                };
                            }
                        }
                    });
                } else if(this[platform + product + slot.dataType] instanceof Array && slot.dataType == 'Inventory') {
                    // Inventory Level Metrics
                    if (!(metrics instanceof Array)) {
                        // Swap the metrics type to an Array
                        metrics = [];
                    }
                    this[platform + product + slot.dataType].forEach((vehicle,i) => {
                        if(metrics[i]) {
                            metrics[i].metrics.push({
                                ...meta,
                                ...vehicle[metric]
                            });
                        } else {
                            metrics[i] = {
                                vin: {
                                    name: 'VIN',
                                    key: 'vin',
                                    ...vehicle.vin,
                                },
                                make: {
                                    name: 'Make',
                                    key: 'make',
                                    ...vehicle.make_name,
                                },
                                model: {
                                    name: 'Model',
                                    key: 'model',
                                    ...vehicle.model_name,
                                },
                                condition: {
                                    name: 'Condition',
                                    key: 'condition',
                                    ...vehicle.condition
                                },
                                days_listed: {
                                    name: 'Days Listed',
                                    key: 'days_listed',
                                    ...vehicle.days_seen
                                },
                                status: {
                                    name: 'Status',
                                    key: 'status',
                                    ...vehicle.status
                                },
                                metrics: [{
                                ...meta,
                                ...vehicle[metric]
                                }]
                            };
                        }
                    });
                } else if(this[platform + product + slot.dataType] instanceof Array) {
                    // Series Data Metrics
                    const seriesDataMetrics = this[platform + product + slot.dataType];
                    if (seriesDataMetrics.length) {
                        if(this[platform + product + slot.dataType][0].hasOwnProperty('stats')) {
                            metrics[metric] = {
                                ...meta,
                                series: this[platform + product + slot.dataType].map(interval => [new Date(interval.date_start?.value || interval?.start_date || interval.date.formatted), interval.stats[metric].value])
                            };
                        } else {
                            metrics[metric] = {
                                ...meta,
                                series: this[platform + product + slot.dataType].map(interval => [new Date(interval.date_start?.value || interval.date.formatted), interval[metric].value])
                            };
                        }
                    }
                } else {
                    // Totaled Metrics
                    const totaledMetrics = this[platform + product + slot.dataType];
                    if (totaledMetrics) {
                        if(this[platform + product + slot.dataType].hasOwnProperty('stats')) {
                            metrics[metric] = {
                                ...meta,
                                ...this[platform + product + slot.dataType].stats[metric]
                            };
                        } else {
                            metrics[metric] = {
                                ...meta,
                                ...this[platform + product + slot.dataType][metric]
                            };
                        }
                    }
                }
            });
            return metrics;
        },
        formatTitle(title) {
            if (title.includes('Offline Events')) return 'Offline Events';
            return title;
        },
        addPage(event) {
            this.$emit('add-page',event);
        }
    }
};
</script>
<style lang="scss">

@page {
    size: 11in 8.5in;
}
.page-preview {
    position: relative;
    overflow: hidden;
    page-break-after: always;
    width: 11in;
    height: 8.5in;
    border: 1px solid #292F31;

    &.in-editor {
        width: 1056px;
        height: 816px;
    }
}

</style>

<style lang="scss" scoped>
* {
    font-family: 'Open Sans' !important;
}
.page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
        font-weight: 600;
        font-size: 32px !important;
        text-transform: uppercase;
        color: #3F4649;
        &.small {
            font-size: 20px !important;
        }
    }
}
.page-preview {
    background-size: cover;
}
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.dealer-missing-feature {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border: 2px solid $carnation;
}
.widget-title {
    font-family: 'Open Sans',sans-serif;
    text-transform: uppercase;
    color: #6A7073;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    img {
        max-width: 25px;
        max-height: 25px;
        height: auto;
        display: inline-block;
        margin-right: 10px;
    }
}
// AVAILABLE BACKGROUNDS
.connecting-nodes {
    background: url('./assets/backgrounds/connecting_nodes.jpg');
}
</style>